import { Button, Form } from "react-bootstrap";
import { DropdownCloseIcon } from "../components/Icons";

export function FilesAttachments({ files = [], setFiles, url, deleteImage }) {
  return (
    <div className="container">
      <div className="row">
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center col-6 mb-3">
            {el?.fileId && url ? (
              <img
                src={`${url}/${el.systemFileName}`}
                className="img-fluid"
                alt={el?.fileId}
                width={100}
              />
            ) : (
              <img
                src={URL.createObjectURL(el)}
                alt={`new${index}`}
                className="img-fluid"
                width={100}
              />
            )}

            <Button
              variant=""
              size="sm"
              onClick={() => {
                if (deleteImage) {
                  deleteImage(index);
                } else {
                  setFiles(files.filter((el, i) => i !== index));
                }
              }}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}
