import { action, createStore, persist } from "easy-peasy";
import { initialGeneralSettings } from "./utils/helpers";

const store = createStore(
  persist(
    {
      itemMeasurements: [],
      setItemMeasurements: action((state, payload) => {
        state.itemMeasurements = payload;
      }),
      defaultCustomer: {},
      setDefaultCustomer: action((state, payload) => {
        state.defaultCustomer = payload;
      }),
      generalSettings: {
        ...initialGeneralSettings,
      },
      setGeneralSettings: action((state, payload) => {
        state.generalSettings = payload;
      }),
      selectedCompanyForAnalytics: {
        value: "",
        label: "",
        url: "",
      },
      setSelectedCompanyForAnalytics: action((state, payload) => {
        state.selectedCompanyForAnalytics = payload;
      }),
      cart: [],
      addToCart: action((state, payload) => {
        state.cart = [...state.cart, payload];
      }),
      removeFromCart: action((state, payload) => {
        state.cart = state.cart.filter(
          (el) => el.Bar_Code !== payload.Bar_Code
        );
      }),
      addQuantityToCart: action((state, payload) => {
        if (payload.Quantity <= 0) {
          state.cart = state.cart.filter(
            (el) => el.Bar_Code !== payload.Bar_Code
          );
          return;
        }
        state.cart[payload.index].Quantity = payload.Quantity;
      }),
      setCart: action((state, payload) => {
        state.cart = payload;
      }),
    },
    {
      storage: "localStorage",
    }
  )
);

export default store;
