import { useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Modal, Table, Form, Button, InputGroup } from "react-bootstrap";
import { services } from "../../config";
import useDebounce, { useIsAdmin } from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";

import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from "easy-peasy";

export default function AddItemModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
}) {
  const durationOptions = [
    {
      value: "Day",
      label: "Day",
    },
    {
      value: "Week",
      label: "Week",
    },
    {
      value: "Month",
      label: "Month",
    },
  ];

  const { backendUrl } = useAuth();

  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();
  const formik = useFormik({
    initialValues: {
      Quantity: 1,
      UnitPrice: "",
      PriceSold: "",
      durationNumber: 1,
      durationValue: "Month",
      Warranty: false,
      Discount: 0,
    },
    validationSchema: yup.object().shape({
      Quantity: yup
        .number()
        .required()
        .min(1),
      UnitPrice: yup.number().required(),
      PriceSold: yup.number().required(),
    }),
    onSubmit: (values) => {
      console.log(values);

      if (Number(values.Quantity) > Number(selectedItem?.quantityInStock)) {
        return formik.setFieldError(
          "Quantity",
          `${selectedItem?.quantityInStock}  item in stock`
        );
      }

      if (values.Warranty) {
        values.Warrant_Duration = `${values.durationNumber} ${
          values.durationValue
        }`;
      } else {
        values.Warrant_Duration = " ";
      }

      /*  selectedItem.UnitCost = currency(selectedItem.UnitCost, {
        symbol: "",
        separator: "",
      }).format(); */

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      values.defaultSubTotal = currency(values.UnitPrice, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format();

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(values.Quantity)
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      }).format();

      values.Profit = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .subtract(selectedItem.UnitCost)
        .multiply(values.Quantity)
        .format();

      console.log(values);

      handleAddItem({ ...selectedItem, ...values });
    },
  });

  const quantityRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [searchString, setSearchString] = useState("");

  /* const [itemFormData, setItemFormData] = useState({
    quantityInStock: 0,
  }); */
  const debouncedSearchString = useDebounce(searchString, 800);

  const getItems = async (q) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/items?&q=${q}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    [queryActions.GET_ITEMS, debouncedSearchString],
    () => getItems(debouncedSearchString),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSelectedItem = (item) => {
    item.quantityInStock = item.Quantity;
    setselectedItem({ ...item });
    /*  setItemFormData({
      ...item,
      finalSellingPrice: item.UnitPrice,
      Quantity: "",
    }); */

    const UnitPrice = currency(item.UnitPrice, {
      symbol: "",
      separator: "",
    }).format();

    formik.setFieldValue("Quantity", 1);
    formik.setFieldValue("UnitPrice", UnitPrice);
    formik.setFieldValue("PriceSold", UnitPrice);

    setTimeout(() => {
      const el = document.querySelector('input[name="Quantity"]');
      if (el) {
        el.focus();
        el.select();
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 50);
  };

  useEffect(() => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.Discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
  }, [formik.values.Discount]);

  return (
    <Modal
      show={true}
      onHide={() => setShowItemSelectorModal(false)}
      dialogClassName="item-select-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add Item</h1>
          <p>Select item and set the pricing details.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="body-content">
          <div className="">
            <h2>Select Item</h2>

            <div className="items-table-area rounded">
              <div className="global-search-area m-3 mx-0">
                <MagnifyIcon />
                <input
                  className="form-control search-input"
                  name="q"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search item..."
                  autoFocus
                  autoComplete="off"
                />
              </div>

              <div className="content">
                <Table borderless striped hover className="product-table">
                  <thead className="sticky">
                    <tr>
                      <th scope="col">Bar_Code</th>
                      <th scope="col">Item_Name</th>
                      {/* <th scope="col">Unit_Cost</th>
                      <th scope="col">Unit_Price</th> */}
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody className="blue-hover">
                    {data.items &&
                      data.items.map((el, index) => (
                        <tr
                          className="p-cursor"
                          key={index}
                          onClick={() => handleSelectedItem(el)}
                        >
                          <td>{el.Bar_Code}</td>
                          <td>{el.Item_Name}</td>
                          {/* <td>
                            {currency(el.UnitCost, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td>
                          <td>
                            {currency(el.UnitPrice, {
                              precision: 2,
                              symbol: "",
                            }).format()}
                          </td> */}
                          <td>
                            {selectedItem?.Bar_Code === el.Bar_Code ? (
                              <Button variant="primary">Selected</Button>
                            ) : (
                              <Button variant="outline-primary">Select</Button>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="postion-relative">
            {/*  No item */}
            {!selectedItem && (
              <div className="no-item">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2>No Item Selected</h2>
                  <p>
                    Please select an item from the table beside to fill in the{" "}
                    <br />
                    pricing details.
                  </p>
                </div>
              </div>
            )}
            <h2>Item Details</h2>

            <Form noValidate onSubmit={formik.handleSubmit}>
              <Form.Group className="mb-2">
                <Form.Label className="slim">Item Name</Form.Label>
                <p className="selected">{selectedItem?.Item_Name || "..."} </p>
              </Form.Group>

              <Form.Group className="mb-3 d-flex flex-wrap justify-content-between gap-3 ">
                <div>
                  <Form.Label className="slim">Item Code</Form.Label>
                  <p className="selected"> {selectedItem?.Bar_Code || "..."}</p>
                </div>

                <div>
                  <Form.Label className="slim">Quantity in Stock</Form.Label>
                  <p className="selected">
                    {selectedItem?.quantityInStock || "..."}
                  </p>
                </div>

                <div>
                  <Form.Label className="slim">Cost Price</Form.Label>
                  <p className="selected">
                    {currency(selectedItem?.UnitCost, {
                      symbol: "",
                    }).format() || "..."}
                  </p>
                </div>
              </Form.Group>
              <hr />

              <h2 className="pt-0">Pricing Details</h2>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Unit Price</Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  value={formik.values.UnitPrice}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                  placeholder="0.00"
                  decimalsLimit={2}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>
                  Discount <span className="slim">(optional)</span>
                </Form.Label>
                <CurrencyCustomInput
                  name="Discount"
                  value={formik.values.Discount}
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                  placeholder="0.00"
                  decimalsLimit={2}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-1">
                <Form.Label>Final Selling price</Form.Label>
                <CurrencyCustomInput
                  name="PriceSold"
                  placeholder="0.00"
                  decimalsLimit={2}
                  value={formik.values.PriceSold}
                  onValueChange={(value, name) =>
                    formik.setFieldValue(name, value)
                  }
                />
                {formik.touched.PriceSold && !!formik.errors.PriceSold ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PriceSold}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label>Quantity</Form.Label>
                <NumberCustomInput
                  placeholder="0"
                  name="Quantity"
                  value={formik.values.Quantity}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value, true);
                  }}
                  isInvalid={
                    formik.touched.Quantity && !!formik.errors.Quantity
                  }
                  onBlur={() => formik.setFieldTouched("Quantity", true)}
                  ref={quantityRef}
                />
                {formik.touched.Quantity && !!formik.errors.Quantity ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Quantity}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Check
                type="switch"
                id="custom-switch"
                label="Enable warranty"
                className="mb-3"
                name="Warranty"
                checked={formik.values.Warranty}
                onChange={formik.handleChange}
              />

              {/*  <Form.Group className="mb-3 pb-2">
                <Form.Label>Set Duration</Form.Label>

                <div className="duration">
                  <NumberCustomInput
                    placeholder="0"
                    name="durationNumber"
                    value={formik.values.durationNumber}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value, true);
                    }}
                    // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                  />

                  <Select
                    classNamePrefix={"form-select"}
                    placeholder={"Month"}
                    value={durationOptions.find(
                      (el) => el.value === formik.values.durationValue
                    )}
                    options={durationOptions}
                    onChange={({ value }) =>
                      formik.setFieldValue("durationValue", value)
                    }
                  />
                </div>
              </Form.Group> */}

              <Button
                disabled={!selectedItem}
                type="submit"
                className="w-100 submit-btn"
              >
                Add to Cart
              </Button>
            </Form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
