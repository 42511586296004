import { Dropdown, Form, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Link, useSearchParams } from "react-router-dom";
import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useEffectOnce, useQueryParams } from "../../utils/hooks";
import Select from "react-select";
import CachedIcon from "mdi-react/CachedIcon";
import NewCustomerModal from "../NewCustomerModal";
import { queryActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import queryString from "query-string";
import { useStoreActions, useStoreState } from "easy-peasy";
import { services } from "../../config";
import {
  customerFullName,
  customerTypeOptions,
  didYouKnowOptions,
  paginationOptions,
  searchParamsToObject,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SetSalesRepModal from "./SetSalesRepModal";
import { format } from "date-fns";
import currency from "currency.js";
import {
  DeleteIcon,
  EditIcon,
  FileListIcon,
  ViewIcon,
  QuotationIcon,
  UserIcon,
} from "../Icons";
import EditCustomerModal from "../EditCustomerModal";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";
import ConfirmDialog from "../ConfirmDialogue";
import CustomerProducts from "../CustomerProducts";

export function CustomerList() {
  const [searchParams] = useSearchParams();
  const { backendUrl, token } = useAuth();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showEditCustomerModal, setShowEditCustomerModal] = useState(false);

  const [showProductModal, setShowProductModal] = useState(false);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showSalesRepManager, setShowSalesRepManager] = useState(null);

  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  const generalSettings = useStoreState((state) => state.generalSettings);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  // action form url query on render once
  useEffectOnce(() => {
    const queryParams = searchParamsToObject(searchParams.entries());
    if (queryParams?.action === "create") {
      setShowCreateNewCustomerModal(true);
      // delete queryParams.action;
      setQueryParams({ ...initialFilterParams });
    }
  });

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { customers: [] }, refetch } = useQuery(
    [queryActions.CUSTOMERS, queryParams],
    () => getCustomer(queryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const deleteCustomer = async (payload) => {
    let response = await fetch(`${backendUrl}/api/customers/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteCustomerMutation = useMutation(
    (payload) => deleteCustomer(payload),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const manageSalesRep = (el) => {
    setSelectedCustomer(el);
    setShowSalesRepManager(true);
  };

  const editCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowEditCustomerModal(true);
  };

  const handlefedeleteCustomer = async (customer) => {
    if (
      await ConfirmDialog({
        title: "Delete Customer",
        description: `Are you sure, you want to delete ${customerFullName(
          customer
        )} `,
      })
    ) {
      deleteCustomerMutation.mutate(customer);
    }
  };

  const createInvoice = async (customer) => {
    console.log(customer);
  };

  return (
    <main className="bg-white">
      <header className="p-4">
        <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
          <div className="d-flex flex-wrap gap-3">
            <Button
              variant="primary"
              onClick={() => setShowCreateNewCustomerModal(true)}
            >
              + Create New
            </Button>

            <div className="d-flex flex-wrap gap-3">
              <div className="global-search-area">
                <MagnifyIcon />
                <Form.Control
                  id="queryParams-q"
                  className=""
                  name="LastName"
                  value={filterParams.LastName}
                  onChange={(e) => handleSearchQueryChange(e)}
                  placeholder="Search..."
                />
              </div>
              <Button
                onClick={() => refetch()}
                variant=""
                className="bg-light-blue border-0 text-primary"
              >
                <CachedIcon />
              </Button>
            </div>
          </div>

          <div
            className="d-flex flex-wrap gap-3"
            style={{ fontSize: "0.875rem" }}
          >
            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="Customer Type"
                name="TransType"
                isSearchable={true}
                onChange={(selected) => {
                  setFilterParams({
                    ...filterParams,
                    TransType: selected.value,
                  });
                }}
                value={customerTypeOptions.find(
                  (el) => el.value === filterParams.TransType
                )}
                options={customerTypeOptions || []}
              />
            </Form.Group>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                placeholder="How did you know about us"
                name="How_Did_Know"
                isSearchable={true}
                onChange={(selected) => {
                  setFilterParams({
                    ...filterParams,
                    How_Did_Know: selected.value,
                  });
                }}
                value={didYouKnowOptions.find(
                  (el) => el.value === filterParams.How_Did_Know
                )}
                options={didYouKnowOptions || []}
              />
            </Form.Group>
          </div>
        </div>
      </header>

      <div className="content px-4 pb-4">
        <Table borderless striped responsive className="product-table">
          <thead className="sticky border-bottom">
            <tr>
              <th />
              <th>Cust ID</th>
              <th>Customer Name</th>
              <th>Transaction Type</th>
              <th>Phone</th>
              <th>Contact Address</th>
              <th>Company Address</th>
              <th>Email</th>
              <th>Acquisition</th>
              <th>DateLog</th>
              <th>DOB</th>
              <th>Credit Limit</th>
              <th>Branch</th>
            </tr>
          </thead>

          <tbody className="blue-hover">
            {data.customers &&
              data.customers.map((el, index) => (
                <tr key={index}>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="bg-white border-0"
                        bsPrefix="print more"
                      >
                        <DotsVerticalIcon />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        popperConfig={{
                          strategy: "fixed",
                        }}
                        renderOnMount
                        className="dropdown-with-icons"
                      >
                        <Dropdown.Item
                          onClick={() => editCustomer(el)}
                          as="button"
                        >
                          <EditIcon />
                          Edit Customer
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setSelectedCustomer(el);
                            setShowProductModal(true);
                          }}
                          as="button"
                        >
                          <UserIcon />
                          Add Customer Product
                        </Dropdown.Item>
                        <Link
                          to={`/sales-and-invoicing/create-proforma-invoice`}
                          state={{ customer: el }}
                        >
                          <Dropdown.Item as="button">
                            <QuotationIcon />
                            Create {`${generalSettings.pendingInvoiceType}`}
                          </Dropdown.Item>
                        </Link>
                        <Link
                          to={`/sales-and-invoicing/create-invoice`}
                          state={{ customer: el }}
                        >
                          <Dropdown.Item as="button">
                            <EditIcon />
                            Create Invoice
                          </Dropdown.Item>
                        </Link>
                        <Dropdown.Item
                          onClick={() => manageSalesRep(el)}
                          as="button"
                        >
                          <EditIcon />
                          Manage Sales Rep
                        </Dropdown.Item>
                        <Link
                          to={`/reports/sales-analysis?customerId=${
                            el.Cust_ID
                          }&startDate=&endDate=`}
                          state={{ customer: el }}
                        >
                          <Dropdown.Item as="button">
                            <ViewIcon />
                            View Sales History
                          </Dropdown.Item>
                        </Link>
                        <Link
                          to={`/reports/customer-ledger?customerId=${
                            el.Cust_ID
                          }&customerName=${customerFullName(el)}`}
                        >
                          <Dropdown.Item as="button">
                            <ViewIcon />
                            View Customer Ledger
                          </Dropdown.Item>
                        </Link>
                        <Dropdown.Item
                          onClick={() => handlefedeleteCustomer(el)}
                          as="button"
                        >
                          <DeleteIcon />
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>{" "}
                    </Dropdown>
                  </td>
                  <td>{el.Cust_ID}</td>
                  <td>{customerFullName(el)}</td>
                  <td>{el.TransType}</td>
                  <td>{el.PhoneNo1}</td>
                  <td>{el?.ContactAddress || "..."}</td>
                  <td>{el?.CompanyAddress || "..."}</td>

                  <td>{el.Email || "..."}</td>
                  <td>{el.How_Did_Know}</td>
                  <td>{el.DateLog}</td>
                  <td className="text-nowrap">
                    {el.DOB ? format(new Date(el.DOB), "dd MMM") : "..."}
                  </td>
                  <td>
                    {currency(el?.Customer_CreditLimit?.CreditLimit, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>{el.Branch}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
        {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
        <div className="pagination_left d-flex gap-3 align-items-center">
          <p className="m-0 p-0">Show</p>
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleSearchQueryChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>

      {showCreateNewCustomerModal && (
        <NewCustomerModal
          showCreateNewCustomerModal={showCreateNewCustomerModal}
          setShowCreateNewCustomerModal={setShowCreateNewCustomerModal}
          refetch={refetch}
        />
      )}

      {showEditCustomerModal && selectedCustomer ? (
        <EditCustomerModal
          showEditCustomerModal={showEditCustomerModal}
          setShowEditCustomerModal={setShowEditCustomerModal}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          refetch={refetch}
        />
      ) : null}

      {showSalesRepManager && selectedCustomer ? (
        <SetSalesRepModal
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          setShowSaleRepModal={setShowSalesRepManager}
        />
      ) : null}

      {showProductModal && selectedCustomer ? (
        <CustomerProducts
          showProductModal={showProductModal}
          setShowProductModal={setShowProductModal}
          selectedCustomer={selectedCustomer}
          itemRefetch={refetch}
        />
      ) : null}

      <ModalLoader show={deleteCustomerMutation.isLoading} />
    </main>
  );
}
